import React from "react";
import pathConstants from "./pathConstants";
import AddProject from "../../adminPages/AddProject";
import AddNewTimeSheet from "../../adminPages/AddNewTimeSheet";
import ProjectViewTimeSheet from "../../adminPages/ProjectViewTimeSheet";
import EmployeeViewTimeSheet from "../../adminPages/EmployeeViewTimeSheet";
import LinkEmployeeHistory from "../../adminPages/LinkEmployeeHistory";
import CalendarView from "../../employeePages/CalenderView";
import EmployeeViewTimeSheetEmp from "../../employeePages/EmployeeViewTimeSheetEmp";
import DeliveryManager from "../../components/DeliveryManager";
import DeliveryMangerView from "../../components/DeliveryMangerView";
import Timesheet from "../../employeePages/Timesheet";
import { isDeliveryManager } from "../../utils/userDetails";
import Dashboard from "../../components/Dashboard";


let user = isDeliveryManager;
const adminRoutes = [
  { path: pathConstants.Home, element: <CalendarView /> },
  { path: pathConstants.Timesheet, element: <Timesheet /> },
  {
    path: pathConstants.EmployeeViewEmp,
    element: <EmployeeViewTimeSheetEmp />,
  },
  { path: pathConstants.AddProject, element: <AddProject /> },
  { path: pathConstants.AddNewTimeSheet, element: <AddNewTimeSheet /> },
  { path: pathConstants.EmployeeView, element: <EmployeeViewTimeSheet /> },
  { path: pathConstants.ProjectView, element: <ProjectViewTimeSheet /> },
  { path: pathConstants.EmployeeHistory, element: <LinkEmployeeHistory /> },
];

if (user) {
  adminRoutes.push(
    {
      path: pathConstants.DeliveryManager,
      element: <DeliveryManager />,
    },
    {
      path: pathConstants.view,
      element: <DeliveryMangerView />,
    },
    {
      path: pathConstants.Dashboard,
      element: <Dashboard />,
    }
  );
}
export default adminRoutes;
