import {  isDeliveryManager, userRole } from "../../utils/userDetails";

let user=userRole;
const pathConstants={
    Home:'/',
    Timesheet:'/timesheet',
    EmployeeView:'/my-view',
}
if (isDeliveryManager) {
    pathConstants.ProjectViewTimesheet= '/project-view';
    pathConstants.EmployeeViewTimesheet = '/employee-view';
    pathConstants.DeliveryManager = '/timesheet-approval';
    pathConstants.view='/delivery-manager-view';
    pathConstants.Dashboard = '/approval-dashboard';
}
export default pathConstants