import {
  Button,
  Form,
  Input,
  Select,
  Typography,
  notification,
  Col,
  DatePicker,
  Flex,
  Row,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Project, linkEmployees } from "../../api";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";

const InternalProjectForm = ({ hide, added, project, isEdit }) => {
  const initialLeaveState = {
    startDate: dayjs(),
    endDate: dayjs(),
  };

  const [leave, setLeave] = useState(initialLeaveState);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(null); // State to manage the ReactQuill editor content
  const [deliveryManager, setDeliveryManager] = useState([]);
  const [productManager, setProductManager] = useState([]);
  const { Text } = Typography;
  const reactQuillRef = useRef();
  const [errorMessageStart, setErrorMessageStart] = useState("");
  const [errorMessageEnd, setErrorMessageEnd] = useState("");

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      projectDescription: null,
    });
    setDescription(null);
    if (isEdit && project) {
      form.setFieldsValue({
        projectName: project.projectName,
        projectDescription: project.projectDescription,
        deliveryManagerId: project.deliveryManagerId,
        productManagerId: project.productManagerId,
        startDate: dayjs(project.startDate),
        endDate: project.endDate ? dayjs(project.endDate) : null,
      });
      setDescription(project.projectDescription); // Set the initial value for ReactQuill editor
      setLeave({
        startDate: dayjs(project.startDate),
        endDate: dayjs(project?.endDate),
      });
    }
  }, [isEdit, project, form]);

  const openNotification = (message, icon) => {
    api.open({
      message: message,
      duration: 2,
      icon: icon,
    });
  };

  const getAllEmployees = async () => {
    try {
      const response = await linkEmployees.getAllEmployees();
      const newResponse = response.employees.map((employee) => {
        return {
          value: employee.employeeNo,
          label: `${employee.employeeName}  (${employee.employeeNo})`,
        };
      });
      setDeliveryManager(newResponse);
      setProductManager(newResponse);
    } catch (error) {
      console.log("get all employee error", error);
    }
  };

  useEffect(() => {
    getAllEmployees();
  }, []);

  const setStartDate = (value) => {
    if (value) {
      const formattedDate = dayjs(value);
      const endDate = leave.endDate;

      setLeave((prevLeave) => ({
        ...prevLeave,
        startDate: formattedDate,
      }));

      form.setFieldsValue({ startDate: formattedDate });

      if (formattedDate.isAfter(endDate)) {
        setErrorMessageStart("Start date cannot be greater than End date");
        setErrorMessageEnd("");
      } else {
        setErrorMessageStart("");
        setErrorMessageEnd("");
      }
    }
  };

  const setEndDate = (value) => {
    if (value) {
      const formattedEndDate = dayjs(value);
      const startDate = leave.startDate;

      setLeave((prevLeave) => ({
        ...prevLeave,
        endDate: formattedEndDate,
      }));

      form.setFieldsValue({ endDate: formattedEndDate });

      if (formattedEndDate.isBefore(startDate)) {
        setErrorMessageEnd("End date cannot be less than Start date");
        setErrorMessageStart("");
      } else {
        setErrorMessageEnd("");
        setErrorMessageStart("");
      }
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      values.startDate = leave.startDate.format("YYYY-MM-DD");
      values.endDate = values?.endDate ? leave?.endDate?.format("YYYY-MM-DD") : null;

      if (isEdit) {
        await Project.addProject(values);
        openNotification(
          "Project Updated Successfully !!",
          <CheckCircleFilled style={{ color: "#52c41a" }} />
        );
      } else {
        await Project.addProject(values);
        openNotification(
          "Project Added Successfully !!",
          <CheckCircleFilled style={{ color: "#52c41a" }} />
        );
      }
      form.resetFields();
      hide();
      added(true);
    } catch (error) {
      form.resetFields();
      hide();
      openNotification(
        "Failed to Add/Update Project",
        <CloseCircleFilled style={{ color: "#ff4d4f" }} />
      );
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDescription = (value) => {
    setDescription(value);
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, "text/html");
    const textContent = doc.body.textContent;
    form.setFieldsValue({
      projectDescription: textContent ? value : "",
    });
  };

  const checkCharacterCount = (event) => {
    const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    if (unprivilegedEditor.getLength() > 2000 && event.key !== "Backspace")
      event.preventDefault();
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Form
      form={form}
      layout={"vertical"}
      initialValues={{
        layout: "vertical",
        remember: true,
      }}
      onFinish={onFinish}
      style={{
        padding: "1.5rem 1rem 0rem",
        margin: "auto",
        overflow: "hidden",
      }}
      className="userForm"
    >
      <div className="form">
        <Form.Item
          name="projectName"
          label={<Text style={{ fontSize: "16px" }}>Name</Text>}
          rules={[{ required: true, message: "Project Name is Required" }]}
          style={{ margin: "0 auto 24px" }}
        >
          <Input
            placeholder="Project Name"
            size="large"
            disabled={!!project}
            maxLength={500}
          />
        </Form.Item>

        <Form.Item
          name="deliveryManagerId"
          label={<Text style={{ fontSize: "16px" }}>Delivery Manager</Text>}
          rules={[{ required: true, message: "Delivery Manager is Required" }]}
          style={{ margin: "0 auto 24px" }}
        >
          <Select
            showSearch
            placeholder="Select Delivery Manager"
            size="large"
            options={deliveryManager}
            filterOption={filterOption}
            optionFilterProp="children"
          />
        </Form.Item>

        <Form.Item
          name="productManagerId"
          label={<Text style={{ fontSize: "16px" }}>Product Manager</Text>}
          rules={[{ required: true, message: "Product Manager is Required" }]}
          style={{ margin: "0 auto 24px" }}
        >
          <Select
            showSearch
            placeholder="Select Product Manager"
            size="large"
            options={productManager}
            filterOption={filterOption}
            optionFilterProp="children"
          />
        </Form.Item>

        <Flex>
          <Row gutter={[8, 8]} style={{ width: "100%" }}>
            <Col span={12}>
              <span>
                Start Date
                {<span style={{ color: "red", fontSize: "15px" }}>*</span>}
              </span>
              <Form.Item name="startDate" help={errorMessageStart}>
                <DatePicker
                  style={{ width: "200%" }}
                  placeholder="Start Date"
                  value={leave.startDate}
                  onChange={(value) => setStartDate(value)}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[8, 8]} style={{ width: "100%" }}>
            <Col span={12}>
              <span>
                End Date
                {/* {<span style={{ color: "red", fontSize: "15px" }}>*</span>} */}
              </span>
              <Form.Item name="endDate" help={errorMessageEnd}>
                <DatePicker
                  style={{ width: "200%" }}
                  placeholder="End Date"
                  value={leave.endDate}
                  onChange={(value) => setEndDate(value)}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Flex>

        <Form.Item
          name="projectDescription"
          label={<Text style={{ fontSize: "16px" }}>Description</Text>}
          rules={[
            {
              required: true,
              message: "Task Performed is Required",
            },
          ]}
        >
          <div
            style={{
              borderRadius: "2px",
              overflow: "hidden",
              zIndex: "10",
              height: "200px",
              marginBottom: "1rem",
            }}
          >
            <ReactQuill
              style={{
                width: "100%",
                height: "150px",
              }}
              ref={reactQuillRef}
              onKeyDown={checkCharacterCount}
              value={description}
              onChange={handleDescription}
              theme="snow"
              modules={{
                toolbar: [
                  [{ font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
            />
          </div>
        </Form.Item>
      </div>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading}
          style={{ display: "block", width: "100%" }}
        >
          Save
        </Button>
      </Form.Item>
      {contextHolder}
    </Form>
  );
};

export default InternalProjectForm;
