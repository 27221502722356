import {
  Button,
  Col,
  Flex,
  Form,
  Modal,
  Progress,
  Radio,
  Row,
  Select,
  TimePicker,
  Tooltip,
  notification,
  Spin,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../Styles/CalendarView.css";
import { Activity, employeeApi } from "../api";
import ApplyLeave from "../components/ApplyLeave";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { employeeId } from "../utils/userDetails";
import { convertTime } from "../utils/dateFormat";
const localizer = momentLocalizer(moment);

const CalendarView = () => {
  const [api, contextHolder] = notification.useNotification();
  const format = "HH:mm";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [isApplyLeaveOpen, setIsApplyLeave] = useState(false);
  const [employeeProjects, setEmployeeProject] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [date, setDate] = useState(moment());
  const [projects, setProjects] = useState([]);
  const [activity, setActivity] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment().month());
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [leaveApplied, setLeaveApplied] = useState(false);
  const [taskDetails, setTaskDetails] = useState({
    project: null,
    activity: null,
    timeWorked: null,
    description: null,
    id: null,
    leaveType: null,
    projectId: null,
    type: null,
  });
  const reactQuillRef = useRef();
  const radioOptions = [
    { label: "Projects", value: "Projects" },
    { label: "Activities", value: "Add Activities" },
  ];
  const [value, setValue] = useState("Projects");
  const [loadingSaveButton, setLoadingSaveButton] = useState(false);
  const [loadCalenderData, setLoadCalenderData] = useState(false);

  const openNotification = (message, icon) => {
    api.open({
      message: message,
      duration: 2,
      icon: icon,
    });
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const formRef = useRef();

  useEffect(() => {
    if (!isApplyLeaveOpen) {
      getTotalLeave();

      if (leaveApplied) {
        getCalenderData();
        setLeaveApplied(false);
      }
    }
  }, [isApplyLeaveOpen, leaveApplied]);

  const getTotalLeave = async () => {
    await employeeApi
      .retriveLeave({
        employeeId: employeeId,
      })
      .then((response) => {
        const selectedProjectObj = {};
        response.data.forEach((value) => {
          const dateKey = moment(value.leaveDate).format("DD-MM-YYYY");
          if (!selectedProjectObj[dateKey]) {
            selectedProjectObj[dateKey] = value.leaveType;
          }
        });
        setLeaveData(selectedProjectObj);
      })
      .catch((error) => {
        console.error("Error fetching leave data:", error);
      });
  };

  const getCalenderData = async () => {
    setLoadCalenderData(false);
    await employeeApi
      .getCalender({
        employeeNo: employeeId,
        month: currentMonth,
        year: currentYear,
      })
      .then((response) => {
        const formattedProjects = response.data.flatMap((item) =>
          item.tasks.map((task) => ({
            id: task.id,
            projectId: task.projectid,
            title: task.projectName,
            start: moment(task.date).toDate(),
            end: moment(task.date).toDate(),
            minutesWorked: task.minutes_worked,
            leave: task.isLeave,
            leaveType: task.leaveType,
            description:
              task.leaveType === 2
                ? task.tasks_performed.replace(/<p>Leave: HALF DAY<\/p>/, "")
                : task.tasks_performed,
            type: task.type,
            approvalStatus: task.approvalStatus,
          }))
        );

        formattedProjects.sort((a, b) => {
          if (a.projectId < b.projectId) return -1;
          if (a.project > b.projectId) return 1;
          return 0;
        });

        const selectedProjectObj = {};
        formattedProjects.forEach((project) => {
          const dateKey = moment(project.start).format("DD-MM-YYYY");
          if (!selectedProjectObj[dateKey]) {
            selectedProjectObj[dateKey] = [];
          }
          selectedProjectObj[dateKey].push(project);
        });
        setProjects(formattedProjects);
        setLoadCalenderData(true);

        setSelectedProject(selectedProjectObj);
      })
      .catch((error) => {
        console.log("employee calender api error", error);
      });
  };

  useEffect(() => {
    getCalenderData();
  }, [currentMonth, currentYear]);

  const handleTimeWorked = (e) => {
    const time = e?.format("HH:mm");
    setTaskDetails({
      ...taskDetails,
      timeWorked: time,
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    form.setFieldsValue({
      selectedProject: taskDetails.project,
      descriptionOfProject: taskDetails.description,
      timeWorked: taskDetails.timeWorked
        ? dayjs(taskDetails.timeWorked, format)
        : null,
      selectedActivity: taskDetails.activity,
    });
  }, [taskDetails, form]);

  const handleDescription = (value) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = value;
    const textContent = tempElement.textContent || tempElement.innerText || "";
    const hasText = textContent.trim().length > 0;

    if (hasText) {
      setTaskDetails((prevTaskDetails) => ({
        ...prevTaskDetails,
        description: value,
      }));
      form.setFieldsValue({
        descriptionOfProject: value,
      });
    } else {
      setTaskDetails((prevTaskDetails) => ({
        ...prevTaskDetails,
        description: null,
      }));
      form.setFieldsValue({
        descriptionOfProject: null,
      });
    }
  };
  const checkCharacterCount = (event) => {
    const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    if (unprivilegedEditor.getLength() > 2000 && event.key !== "Backspace")
      event.preventDefault();
  };
  const openModal = (slotInfo) => {
    const selectedDate = moment(slotInfo.start);
    const formattedDate = selectedDate.format("DD-MM-YYYY");

    setDate(formattedDate);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    const matchedProject = projects.find(
      (project) =>
        project.projectId === taskDetails.projectId &&
        project.approvalStatus === 3
    );

    if (matchedProject) {
      openNotification(
        "Project is already approved, Cannot be updated !!",
        <CloseCircleFilled style={{ color: "#f5222d" }} />
      );
      return;
    }
    setLoadingSaveButton(true);
    form
      .validateFields()
      .then(() => {
        const name = value === "Add Activities" ? "activityId" : "projectId";
        employeeApi
          .addTask({
            [name]: taskDetails.projectId,
            logDate: date.split("-").reverse().join("-"),
            minutesWorked:
              Number(taskDetails.timeWorked.split(":")[0]) * 60 +
              Number(taskDetails.timeWorked.split(":")[1]),
            description: taskDetails.description,
            employeeNo: employeeId,
          })
          .then((response) => {
            setLoadingSaveButton(false);
            setIsModalOpen(false);
            openNotification(
              `Logs ${taskDetails.id ? "updated" : "added"}  Successfully !!`,
              <CheckCircleFilled style={{ color: "#52c41a" }} />
            );
            getCalenderData();
            return response;
          })
          .catch((error) => {
            setLoadingSaveButton(false);
            setIsModalOpen(false);
            openNotification(
              `${value === "Add Activities" ? "Activity" : "Project task"} ${
                taskDetails.id ? "added" : "updated"
              }  Failed !!`,
              <CloseCircleFilled style={{ color: "#ff4d4f" }} />
            );
            console.error("There was an error with the fetch request:", error);
          });
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
        setLoadingSaveButton(false);
      });
  };

  const getEmployeeProjectsByDate = async (data) => {
    setEmployeeProject([]);
    try {
      const response = await employeeApi.getEmployeeProjectsByDate(data);
      const projects = response.map(({ projectName, projectId }) => {
        return { label: projectName, value: projectId };
      });
      setEmployeeProject(projects);
    } catch (error) {
      console.log("employee Project by date", error);
    }
  };

  const getAllActivity = async () => {
    try {
      const response = await Activity.getActivity();
      const activities = response.activities.map(
        ({ activityId, activityName }) => ({
          label: activityName,
          value: activityId,
        })
      );
      setActivity(activities);
    } catch (error) {
      console.log("get all activity", error);
    }
  };

  const resetForm = () => {
    setTaskDetails({
      project: null,
      timeWorked: null,
      description: null,
      id: null,
      activity: null,
      leaveType: null,
      projectId: null,
    });
  };
  useEffect(() => {
    if (!isModalOpen) {
      setTaskDetails({
        project: null,
        timeWorked: null,
        description: null,
        id: null,
        activity: null,
        leaveType: null,
        projectId: null,
      });
    }
  }, [isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
    let timeout;
    timeout = setTimeout(() => {
      form.resetFields();
    }, 200);
    return () => clearTimeout(timeout);
  };

  const handleDeleteLogs = () => {
    const matchedProject = projects.find(
      (project) =>
        project.projectId === taskDetails.projectId &&
        project.approvalStatus === 3
    );

    if (matchedProject) {
      openNotification(
        "Project is already approved, Cannot be updated !!",
        <CloseCircleFilled style={{ color: "#f5222d" }} />
      );
      return;
    }
    Modal.confirm({
      title: "Are you sure you want to delete the activity log?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        employeeApi
          .deleteCalenderActivity({
            projectId: taskDetails.projectId,
            logDate: date.split("-").reverse().join("-"),
          })
          .then((response) => {
            if (response.status === "200") {
              openNotification(
                "Log deleted successfully",
                <CheckCircleFilled style={{ color: "#52c41a" }} />
              );
              getCalenderData();
              setIsModalOpen(false);
              let timeout;
              timeout = setTimeout(() => {
                form.resetFields();
              }, 200);
              return () => clearTimeout(timeout);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      onCancel: () => {
        console.log("Cancel");
      },
    });
  };

  const addProject = (value) => {
    if (selectedProject[date]) {
      let task = selectedProject[date].filter(
        (item) =>
          value === item.projectId &&
          (item.type === "ClientProject" || item.type === "InternalProject")
      );
      if (task.length) {
        setTaskDetails({
          ...taskDetails,
          project: task[0].title,
          timeWorked: `${Math.floor(task[0].minutesWorked / 60)}:${
            task[0].minutesWorked % 60
          }`,
          description: task[0].description,
          id: task[0].id,
          activity: task[0].activity,
          leaveType: task[0].leaveType,
          projectId: task[0].projectId,
        });
        //if the states are getting updated why can't is see this value in form inside modal
      } else {
        setTaskDetails({
          ...taskDetails,
          project: value,
          projectId: value,
        });
      }
    } else {
      setTaskDetails({
        ...taskDetails,
        project: value,
        projectId: value,
      });
    }
  };

  const addActivity = (value) => {
    if (selectedProject[date]) {
      let task = selectedProject[date].filter(
        (item) => value === item.projectId && item.type === "Activity"
      );
      if (task.length) {
        setTaskDetails({
          ...taskDetails,
          activity: task[0].title,
          timeWorked: `${Math.floor(task[0].minutesWorked / 60)}:${
            task[0].minutesWorked % 60
          }`,
          description: task[0].description,
          id: task[0].id,
          leaveType: task[0].leaveType,
          projectId: task[0].projectId,
        });
      } else {
        setTaskDetails({
          ...taskDetails,
          activity: value,
          projectId: value,
        });
      }
    } else {
      setTaskDetails({
        ...taskDetails,
        activity: value,
        projectId: value,
      });
    }
  };

  const handleSelectEvent = (event) => {
    // if (event.leave) {
    //   return;
    // }
    resetForm();
    if (event.type === "Activity") {
      setValue("Add Activities");
    } else {
      setValue("Projects");
    }

    const hours = Math.floor(event.minutesWorked / 60);
    const minutes = event.minutesWorked % 60;

    // Format time string
    const timeWorked = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    setTaskDetails({
      project: event.title,
      timeWorked: timeWorked,
      description: event.description,
      id: event.id,
      activity: event.projectId,
      leaveType: event.leaveType,
      projectId: event.projectId,
    });
    form.setFieldsValue({
      selectedProject: event.title,
      descriptionOfProject: event.description,
      timeWorked: dayjs(timeWorked, format),
      selectedActivity: event.activity,
    });

    const slotInfo = {
      start: event.start,
      end: event.end,
      action: "select",
      slots: [event.start],
    };

    openModal(slotInfo);
  };

  useEffect(() => {
    getAllActivity();
  }, []);

  const closeLeaveModal = () => {
    setIsApplyLeave(false);
  };

  // here handling the slot selection where a user is only able to select that slot where no any full day leave or holiday present
  const handleSlotSelect = (slotInfo) => {
    setValue("Projects");
    resetForm();
    form.setFieldsValue({
      selectedProject: "",
      descriptionOfProject: "",
      timeInputhours: "",
      timeInputminutes: "",
      timeWorked: null,
      selectedActivity: "",
    });

    const selectedDate = new Date(slotInfo.start);
    const currentDate = new Date();

    if (selectedDate > currentDate) {
      notification.error({
        message: "Invalid Date",
        description: "You cannot log an activity on an upcoming date.",
        duration: 3,
      });
      return;
    }

    getEmployeeProjectsByDate({
      employeeNo: employeeId,
      date: dayjs(selectedDate).format("YYYY-MM-DD"),
    });

    const day = selectedDate.getDate();
    if (slotInfo.box) {
      const element = document.elementFromPoint(
        slotInfo.box?.x,
        slotInfo.box?.y
      );
      const rect = element.getBoundingClientRect(); // Get size and position of the element
      const coords = {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
      };

      const findFinalParent = (element, maxDepth = 10) => {
        let currentElement = element;
        let depth = 0;

        while (
          currentElement.parentNode &&
          depth < maxDepth &&
          !currentElement.classList.contains("rbc-month-row")
        ) {
          currentElement = currentElement.parentNode;
          depth++;
        }

        return currentElement;
      };

      const finalParentElement = findFinalParent(
        document.elementFromPoint(coords.x, coords.y)
      );

      const newElement = finalParentElement.children[0];
      const elementArray = Array.from(
        newElement.querySelectorAll(".date-cell-main")
      );
      let targetElement;
      elementArray.map((div) => {
        if (div.classList.contains(`${day}`)) {
          targetElement = div;
          return;
        }
      });
      openModal(slotInfo);
    }
  };

  const handleMonthChange = (date) => {
    setCurrentMonth(date.month());
    setCurrentYear(date.year());
  };
  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      const currentYear = moment(toolbar.date).year();
      const currentMonth = moment(toolbar.date).month();
      if (currentYear > 2023 || (currentYear === 2023 && currentMonth > 0)) {
        toolbar.onNavigate("PREV");
        handleMonthChange(moment(toolbar.date).subtract(1, "months"));
      }
    };

    const goToNext = () => {
      const currentYear = moment(toolbar.date).year();
      const currentMonth = moment(toolbar.date).month();
      if (currentYear < 2027 || (currentYear === 2027 && currentMonth < 11)) {
        toolbar.onNavigate("NEXT");
        handleMonthChange(moment(toolbar.date).add(1, "months"));
      }
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      handleMonthChange(moment(toolbar.date));
      toolbar.onNavigate("TODAY");
    };

    const addLeave = () => {
      setIsApplyLeave(true);
    };

    return (
      <div>
        <div className="toolbar-container">
          <div className="left-toolbar">
            <Tooltip title="Current Month" arrow={false}>
              <Button
                style={{ width: "70px" }}
                onClick={goToCurrent}
                size="small"
              >
                Current
              </Button>
            </Tooltip>
            <Tooltip title="Previous Month" arrow={false}>
              <Button style={{ width: "70px" }} onClick={goToBack} size="small">
                Previous
              </Button>
            </Tooltip>
            <Tooltip title="Next Month" arrow={false}>
              <Button style={{ width: "70px" }} onClick={goToNext} size="small">
                Next
              </Button>
            </Tooltip>
          </div>
          <div className="toolbar-container-right">
            <div className="label">{toolbar.label}</div>
            <div className="right-toolbar">
              <Button onClick={addLeave} type="primary">
                Add Leave
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: event.leave
          ? "red"
          : event.type === "Activity"
          ? "#FF4D00"
          : event.type === "ClientProject"
          ? "#009355"
          : "#15A7DF",
      },
    };
  };

  const dayStyleGetter = (date) => {
    const dayOfWeek = moment(date).day();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return {
        className: "weekends",
      };
    }

    return {
      style: {
        position: "relative",
        padding: "5px",
        color: "",
      },
    };
  };

  const CustomDay = ({ date }) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const formattedDate = moment(date).format("DD-MM-YYYY");
    const eventsOnDay = projects.filter((event) =>
      moment(event.start).isSame(date, "day")
    );
    const dayOfWeek = moment(date).day();
    const totalHours = eventsOnDay.reduce(
      (accumulator, event) => accumulator + event.minutesWorked,
      0
    );

    let totalProgress = 0;
    let classNameHolder = "date-cell-main ";
    classNameHolder += `${day} `;
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      classNameHolder += "weekend ";
    }
    totalProgress = (totalHours / (8 * 60)) * 100;
    // if (leaveData[formattedDate]) {
    //   if (
    //     leaveData[formattedDate] === 1 &&
    //     !(dayOfWeek === 0 || dayOfWeek === 6)
    //   ) {
    //     classNameHolder += "red-cell ";
    //   } else if (leaveData[formattedDate] === 1) {
    //     totalProgress = (totalHours / (8 * 60)) * 100;
    //   } else {
    //     totalProgress = (totalHours / (8 * 60)) * 100;
    //   }
    // } else {
    //   totalProgress = (totalHours / (8 * 60)) * 100;
    // }
    const status = totalProgress > 100 ? "exception" : "normal";
    return (
      <div className={classNameHolder}>
        {totalProgress !== 0 ? (
          <Tooltip
            title={`Working Hour ${Math.floor(totalHours / 60)} h ${
              totalHours % 60
            } min `}
          >
            <Progress
              style={{ height: "20px", position: "relative", zIndex: 10 }}
              percent={totalProgress}
              success={
                status === "exception"
                  ? {
                      percent: totalProgress % 100,
                      strokeColor: "#00ffa5",
                    }
                  : {
                      percent: totalProgress,
                    }
              }
              type="circle"
              size={20}
              strokeWidth={10}
              showInfo={false}
            />
          </Tooltip>
        ) : null}
      </div>
    );
  };

  const CustomEvent = ({ event }) => {
    return (
      <Flex justify="space-between" align="center">
        <div
          className={`${event.leave ? "leave-event" : ""}`}
        >{`${event.title.slice(0, 12)}${
          event.title.length > 12 ? "..." : ""
        }`}</div>
        <Flex style={{ fontSize: "12px" }} align="center" justify="center">
          {event.leave
            ? event.leaveType === 1
              ? "Leave:Full day"
              : event.leaveType === 2
              ? "Leave:Half day"
              : event.leaveType === 3
              ? "Leave:Holiday"
              : convertTime(event.minutesWorked)
            : convertTime(event.minutesWorked)}
        </Flex>
      </Flex>
    );
  };

  return (
    <div>
      <ApplyLeave
        isModalOpen={isApplyLeaveOpen}
        closeModal={closeLeaveModal}
        leaveStatus={setLeaveApplied}
      />

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <Row justify="space-between">
            <Col>
              {taskDetails.id ? (
                <Button
                  key="delete"
                  type="primary"
                  onClick={handleDeleteLogs}
                  danger
                >
                  <DeleteOutlined /> Delete
                </Button>
              ) : (
                ""
              )}
            </Col>
            <Col>
              <Button
                key="back"
                onClick={handleCancel}
                style={{ marginRight: "5px" }}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                type="primary"
                loading={loadingSaveButton}
                onClick={handleOk}
                disabled={
                  // taskDetails.leaveType === 1 ||
                  // taskDetails.leaveType === 3 ||
                  taskDetails.projectId === null ||
                  taskDetails.timeWorked == null ||
                  taskDetails.description === null
                }
              >
                Save
              </Button>
            </Col>
          </Row>
        }
        width={600}
      >
        <div className="main-form">
          <div className="main-form-heading">
            <h2 className="modal-heading">
              {taskDetails.id ? "Edit Logs " : "Add Logs "}
            </h2>
            &nbsp;
            <h2 className="date-class">{` for ${date}`}</h2>
          </div>
          <Form ref={formRef} form={form} layout="vertical">
            <Form.Item style={{ margin: "1rem auto 24px" }}>
              <Radio.Group
                options={radioOptions}
                disabled={!!taskDetails.id}
                onChange={onChange}
                value={value}
                optionType="button"
                style={{
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="radiogroup"
              />
            </Form.Item>
            {value === "Projects" ? (
              <Form.Item
                name="selectedProject"
                label="Name "
                rules={[
                  {
                    required: true,
                    message: "Please select a project",
                  },
                ]}
              >
                <Select
                  placeholder="Select Project"
                  disabled={!!taskDetails.id}
                  value={taskDetails.project}
                  onChange={(event) => addProject(event)}
                  filterOption={filterOption}
                  optionFilterProp="children"
                  showSearch
                  options={employeeProjects}
                  notFoundContent="No project found for selected date"
                ></Select>
              </Form.Item>
            ) : (
              <Form.Item
                name="selectedActivity"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select Activity"
                  disabled={!!taskDetails.id}
                  value={taskDetails.activity}
                  onChange={(event) => addActivity(event)}
                  filterOption={filterOption}
                  optionFilterProp="children"
                  showSearch
                  options={activity}
                ></Select>
              </Form.Item>
            )}

            <Form.Item
              name="timeWorked"
              label="Log Time"
              rules={[
                {
                  required: true,
                  message: "Please Enter Time",
                },
              ]}
            >
              <TimePicker
                needConfirm={false}
                placeholder="Enter Log Time"
                format={format}
                style={{ width: "100%" }}
                minuteStep={5}
                value={
                  taskDetails.timeWorked
                    ? dayjs(taskDetails.timeWorked, format)
                    : null
                }
                onChange={handleTimeWorked}
                hideDisabledOptions
                popupClassName="custom-timepicker-popup"
                disabledHours={() =>
                  [...Array(24).keys()].filter((hour) => hour > 14)
                }
                showNow={false}
                // disabled={
                //   taskDetails.leaveType === 1 || taskDetails.leaveType === 3
                // }
              />
            </Form.Item>
            {taskDetails.leaveType === 1 && (
              <div style={{ color: "#e60b2f", marginBottom: "0.5rem" }}>
                Leave: FULL DAY
              </div>
            )}
            {taskDetails.leaveType === 2 && (
              <div style={{ color: "#e60b2f", marginBottom: "0.5rem" }}>
                Leave: HALF DAY
              </div>
            )}
            {taskDetails.leaveType === 3 && (
              <div style={{ color: "#e60b2f", marginBottom: "0.5rem" }}>
                Leave: HOLIDAY
              </div>
            )}
            <Form.Item
              name="descriptionOfProject"
              label="Task Performed"
              rules={[
                {
                  required: true,
                  message: "Mention Task Performed",
                },
              ]}
            >
              <div
                style={{
                  borderRadius: "2px",
                  overflow: "hidden",
                  zIndex: "10",
                  height: "250px",
                  marginBottom: "1rem",
                }}
                tabIndex="0"
                onKeyDown={(e) => {
                  if (e.key === "Tab") {
                    e.preventDefault();
                    reactQuillRef.current.focus();
                  }
                }}
              >
                <ReactQuill
                  style={{
                    width: "100%",
                    height: "200px",
                  }}
                  ref={reactQuillRef}
                  onKeyDown={checkCharacterCount}
                  value={taskDetails.description}
                  onChange={handleDescription}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ font: [] }],
                      [{ size: [] }],
                      ["bold", "italic", "underline", "strike"],
                      [{ list: "ordered" }, { list: "bullet" }],
                    ],
                  }}
                  tabIndex={0}
                  // readOnly={
                  //   taskDetails.leaveType === 1 || taskDetails.leaveType === 3
                  //     ? true
                  //     : false
                  // }
                />
              </div>
            </Form.Item>
          </Form>
        </div>
        {contextHolder}
      </Modal>

      {/* {loadCalenderData ? ( */}
      <Calendar
        localizer={localizer}
        events={projects}
        startAccessor="start"
        endAccessor={(event) => new Date(event.end)}
        style={{
          height: "85vh",
          padding: "1vh 1vw 0",
        }}
        selectable
        onSelectEvent={handleSelectEvent}
        views={["month"]}
        popup
        onSelectSlot={(slotInfo) => handleSlotSelect(slotInfo)}
        components={{
          toolbar: CustomToolbar,
          dateCellWrapper: ({ value, children }) => {
            return <CustomDay date={value}>{children}</CustomDay>;
          },
          event: CustomEvent,
        }}
        dayPropGetter={dayStyleGetter}
        eventPropGetter={eventStyleGetter}
      />
      {/* ) : (
        <div style={{ position: "relative", height: "85vh" }}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
            }}
          >
            <Spin size="large" />
          </div>
          <div style={{ opacity: 0.4,height: "85vh" }}>
            <Calendar
              localizer={localizer}
              style={{
                height: "100%",
                padding: "1vh 1vw 0",
              }}
              components={{
                toolbar: CustomToolbar,
                dateCellWrapper: ({ value, children }) => {},
              }}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default CalendarView;
