import { Flex, Layout } from "antd";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Notification from "../src/utils/headerItems/Notification.js";
import User from "../src/utils/headerItems/User.js";
import "./App.css";
import AdminLayout from "./Layouts/AdminLayout.js";
import EmployeeLayout from "./Layouts/EmployeeLayout.js";
import adminRoutes from "./Routes/adminRoutes";
import employeeRoutes from "./Routes/employeeRoutes";
import { isDeliveryManager, userRole } from "./utils/userDetails.js";

const { Header } = Layout;

function App() {
  const user = userRole;
  let currentRoute;
  if (user === "") {
    currentRoute = {
      path: "/",
      element: <EmployeeLayout />,
      children: employeeRoutes,
    };
  } else if (user === "admin") {
    currentRoute = {
      path: "/",
      element: <AdminLayout />,
      children: adminRoutes,
    };
  } else if (isDeliveryManager) {
    currentRoute = {
      path: "/",
      element: <EmployeeLayout />,
      children: employeeRoutes,
    };
  }

  const router = createBrowserRouter([currentRoute].filter(Boolean));

  return (
    <Layout style={{ height: "100vh" }}>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 23px",
        }}
      >
        <Flex>
          <img
            src="https://spanidea.com/in/wp-content/uploads/2022/08/png-white-logo-300x53.png"
            alt="logo"
            style={{ height: "30px" }}
          />
        </Flex>
        <Flex justify="center">
          <span
            style={{
              color: "white",
              fontSize: "25px",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Timesheet Portal
          </span>
        </Flex>
        <Flex gap="20px" className="header-sider">
          <User />
        </Flex>
      </Header>
      <Layout>
        <div className="App">
          <RouterProvider router={router} />
        </div>
      </Layout>
    </Layout>
  );
}

export default App;
