import React from "react";
import { Menu } from "antd";
import {
  CalendarOutlined,
  ProjectOutlined,
  CheckSquareOutlined,
  UserOutlined,
  FolderViewOutlined,
  DashboardOutlined
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import "../Styles/mainLayout.css";
import { isDeliveryManager } from "../utils/userDetails";

function CustomSider() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuItemClick = (path) => {
    navigate(path);
  };

  let user = isDeliveryManager;

  const items = [
    {
      key: "1",
      icon: <CalendarOutlined />,
      label: "Calendar",
      path: "/",
    },
    {
      key: "2",
      icon: <ProjectOutlined />,
      label: "Timesheet",
      path: "/timesheet",
    },
    {
      key: "3",
      icon: <UserOutlined />,
      label: "My View",
      path: "/my-view",
    }
  ];
  
  if (user) {
    items.push(
      {
        key: "4",
        icon: <DashboardOutlined />,
        label: "Approval Dashboard",
        path: "/approval-dashboard",
      },
      {
        key: "5",
        icon: <CheckSquareOutlined />,
        label: "Approve Timesheet",
        path: "/timesheet-approval",
      },
      {
        key: "6",
        icon: <FolderViewOutlined />,
        label: "View Timesheet",
        children: [
          {
            key: "7",
            label: "Project View",
            path: "/project-view",
          },
          {
            key: "8",
            label: "Employee View",
            path: "/employee-view",
          },
        ],
      }
    );
  }

  // Function to find the selected key
  const findSelectedKey = (path) => {
    let selectedKey;
    items.forEach((item) => {
      if (item.path === path) {
        selectedKey = item.key;
      }
      if (item.children) {
        item.children.forEach((child) => {
          if (child.path === path) {
            selectedKey = child.key;
          }
        });
      }
    });
    return selectedKey;
  };

  const selectedKey = findSelectedKey(location.pathname);

  // Extract open keys for submenus based on the current path
  const openKeys = items
    .filter(
      (item) =>
        item.children &&
        item.children.some((child) => location.pathname.startsWith(child.path))
    )
    .map((item) => item.key);

  return (
    <div className="contentWrapper">
      <Menu
        theme="light"
        selectedKeys={[selectedKey]}  // Use the dynamic selected key
        defaultOpenKeys={openKeys}    // Automatically open the submenu if a child path is active
        mode="inline"
      >
        {items.map((item) =>
          item.children ? (
            <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
              {item.children.map((child) => (
                <Menu.Item
                  key={child.key}
                  onClick={() => handleMenuItemClick(child.path)}
                >
                  {child.label}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              onClick={() => handleMenuItemClick(item.path)}
            >
              {item.label}
            </Menu.Item>
          )
        )}
      </Menu>
    </div>
  );
}

export default CustomSider;
